import React, { useState } from "react"
import TabsStyles from "./tabs.module.css"
import Tab from "../Tab"
function Tabs(props) {
  const [activeTab, setActiveTab] = useState("experience")
  const { children, direction } = props

  const onClickTabItem = tab => {
    setActiveTab(tab)
  }

  return (
    <>
      <div className={direction == "column" ? "col-4" : "col-12"}>
        <div className={`${TabsStyles.tabs} `}>
          <ol
            className={`${TabsStyles.tab_list} ${
              direction === "column" ? TabsStyles.column : TabsStyles.row
            } `}
          >
            {children.map(child => {
              const { label } = child.props

              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                  direction={direction}
                />
              )
            })}
          </ol>
        </div>
      </div>
      <div className={direction == "column" ? "col-8" : "col-12"}>
        {children.map(child => {
          if (child.props.label !== activeTab) return undefined
          return child.props.children
        })}
      </div>
    </>
  )
}
export default Tabs
