import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../components/layout"
import Tabs from "../components/Tabs"
import SEO from "../components/seo"
import COVER from "../images/cover.png"
const SecondPage = props => {
  return (
    <Layout>
      <SEO title="page-2" />
      <div className="row mb-60">
        <div className="col-6">
          <div className="row ">
            <div className="col-12">
              <div className="title">Istanbul Craft Week</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="area">Interactive, Experience.</div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <div className="description">
                Design and art with forgotten craft branches in all Istanbul.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="service">
                Event, Web Site, Mobile Application, Concept Design.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-70">
        <div className="col-12">
          <img src={COVER} alt="cover" />
        </div>
      </div>

      <div className="tabs">
        <input type="radio" name="tabs" id="experience" checked />
        <label className="mb-120" for="experience">
          Experience
        </label>
        <div className="tab">
          <div className="row mb-80">
            <div className="col-6">
              <div className="text_big">
                Istanbul Craft Week is a project that aims to bring Istanbul's
                talent in traditional arts and crafts together with design and
                art by focusing on forgotten craft branches.
              </div>
            </div>
          </div>

          <div className="row mb-30">
            <div className="col-6">
              <div className="row">
                <div className="col-12">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-1.jpg"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-2.jpg"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <img
                alt=""
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-3.jpg"
              />
            </div>
          </div>
          <div className="row mb-100">
            <div className="col-6">
              <div className="text_big">
                Istanbul is a source of inspiration for innovative design ideas
                and feeds today's design ideas as well as traditional crafts and
                production styles that have survived to the present day with its
                multi-layered cultural heritage as a rich venue for traditional
                arts and crafts. The arts and crafts traditions of Istanbul are
                kept alive by the craftsmens of the city and transferred to new
                generations.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <img
                alt=""
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-4.jpg"
                srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-4@2x.jpg"
              />
            </div>
            <div className="col-6">
              <img
                alt=""
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-5.jpg"
                srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-5@2x.jpg"
              />
            </div>
          </div>
          <div className="row mb-70">
            <div className="col-6">
              <img
                alt=""
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-6.jpg"
                srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-6@2x.jpg"
              />
            </div>
            <div className="col-6">
              <img
                alt=""
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-7.jpg"
                srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-7@2x.jpg"
              />
            </div>
          </div>
          <div className="row mb-100">
            <div className="col-6">
              <div className="text_big">
                In light of all this information, Istanbul Craft Week will be
                developed on 17-22 June 2019 within the scope of UNESCO Design
                City Istanbul Project commitments.
              </div>
            </div>
          </div>
          <div className="row mb-150">
            <div className="sliders">
              <input type="radio" name="sliders" id="ateliers" checked />
              <label for="ateliers">6 Ateliers</label>
              <div className="slider">
                <div className="col-8 slider_wrapper">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-1.jpg"
                    srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-1@2x.jpg"
                  />
                </div>
              </div>

              <input type="radio" name="sliders" id="points" />
              <label for="points">52 Craft Points</label>
              <div className="slider">
                <div className="col-8 slider_wrapper">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-2.jpg"
                    srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-2@2x.jpg"
                  />
                </div>
              </div>

              <input type="radio" name="sliders" id="days" />
              <label for="days">6 Days</label>
              <div className="slider">
                <div className="col-8 slider_wrapper">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-1.jpg"
                    srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-1@2x.jpg"
                  />
                </div>
              </div>

              <input type="radio" name="sliders" id="interviews" />
              <label for="interviews">3 Interviews</label>
              <div className="slider">
                <div className="col-8 slider_wrapper">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-2.jpg"
                    srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-2@2x.jpg"
                  />
                </div>
              </div>

              <input type="radio" name="sliders" id="visitors" />
              <label for="visitors">500+ Visitors</label>
              <div className="slider">
                <div className="col-8 slider_wrapper">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-1.jpg"
                    srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-1@2x.jpg"
                  />
                </div>
              </div>

              <input type="radio" name="sliders" id="trip" />
              <label for="trip">Trip Routes</label>
              <div className="slider">
                <div className="col-8 slider_wrapper">
                  <img
                    alt=""
                    src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-6.jpg"
                    srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/gallery-6@2x.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <input type="radio" name="tabs" id="interactive" />
        <label className="mb-120" for="interactive">
          Interactive
        </label>
        <div className="tab">
          <div className="row mb-80">
            <div className="col-6">
              <div className="text_big">
                UNESCO Tasarım Şehri İstanbul projesi taahhütleri kapsamında
                geliştirilen, özellikle unutulmaya yüz tutmuş zanaat dallarına
                odaklanarak İstanbul’un geleneksel sanatlar ve zanaat
                alanlarındaki yeteneğini, tasarım ve sanatla buluşturmayı
                hedefleyen Craft Week projesinin mobil uygulaması ve
                websitesidir.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img
                alt=""
                src="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-interactive.jpg"
                srcSet="https://mnstr.fra1.digitaloceanspaces.com/Manastir/images/website_new/works-detail/icw/icw-interactive@2x.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                id
                title
                path
                cover
              }
            }
          }
        }
      }
    `}
    render={data => <SecondPage data={data} {...props} />}
  />
)
